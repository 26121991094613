.tooltip-component {
  width: 168px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #333333;
}

.tooptip-text {
  color: white;
  font-size: 12px;
  font-weight: normal;
}
